// import React from 'react';
// import { Container, Row, Col } from 'react-bootstrap';

// function ContactUs() {
//   return (
//     <div>
//     <section id="about-us" className="py-5">
//   <Container>
//     <h1 className="text-center mb-4">About Us</h1>

//     {/* First Row */}
//     <Row className="align-items-center">
//       <Col md={6} className="order-md-1">
//         <img src='/images/about1.jpg' alt="About Us 1" className="img-fluid rounded" />
//       </Col>
//       <Col md={6} className="order-md-2">
//         <h2 className="text-center text-md-start mb-3 mb-md-0">Our Journey</h2>
//         <p className="text-center text-md-start">
//           Welcome to Food Paradise, where culinary excellence meets a delightful dining experience. Our journey began with a passion for creating memorable moments through food.
//         </p>
//         <p className="text-center text-md-start">
//           At Food Paradise, our mission is to provide exceptional food quality, warm hospitality, and a diverse range of offerings. We believe in creating a welcoming space where every bite tells a story.
//         </p>
//       </Col>
//     </Row>

//     {/* Second Row */}
//     <Row className="align-items-center mt-5">
//       <Col md={6} className="order-md-2">
//         <img src='/images/about2.jpg' alt="About Us 2" className="img-fluid rounded" />
//       </Col>
//       <Col md={6} className="order-md-1">
//         <h2 className="text-center text-md-end mb-3 mb-md-0">Explore Our Menu</h2>
//         <p className="text-center text-md-end">
//           Explore our menu crafted with love and precision. Whether you're craving a gourmet meal or a quick bite, we have something for every palate.
//         </p>
//       </Col>
//     </Row>
//   </Container>
// </section>


//     </div>
//   )
// }

// export default ContactUs
import React from 'react';

const ContactUs = () => {
  return (
    <div style={containerStyle}>
      <div style={contentStyle}>
        <h2>Contact Us</h2>
        <div style={contactInfoStyle}>
          <div>
            <h4>Meat Store</h4>
            <p>+1 (972) 596-7799</p>
          </div>
          <div>
            <h4>Restaurant</h4>
            <p>+1 (972) 596-7786</p>
          </div>
          <div>
            <h4>Email</h4>
            <p>foodparadisetexas@gmail.com</p>
          </div>
        </div>
        <p style={extraContentStyle}>
          Have questions or feedback? Feel free to reach out to us using the contact information provided above.
        </p>
      </div>
    </div>
  );
};

const containerStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100vh',
};

const contentStyle = {
  textAlign: 'center',
};

const contactInfoStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '20px',
  marginTop: '20px',
};

const extraContentStyle = {
  marginTop: '40px',
};

export default ContactUs;

