import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';

const Catering = () => {
  return (
    <Container className="my-5">
      <h2 className="text-center mb-4">Catering Services</h2>

      <Row>
        <Col md={6}>
          {/* Catering Image or Video */}
          <Card>
            {/* You can use an image or video here */}
            <Card.Img variant="top" src="/images/catering1.jpg" alt="Catering Services" />
          </Card>
        </Col>

        <Col md={6}>
          {/* Catering Services Information */}
          <Card>
            <Card.Body>
              <Card.Title>Exquisite Catering for Your Events</Card.Title>
              <Card.Text>
                We specialize in providing exceptional catering services for a variety of events. From weddings to corporate gatherings, our culinary experts craft delightful menus to suit every occasion.
              </Card.Text>
              <Link to="/OurRestaurant"><Button variant="primary">View Menu</Button></Link>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Catering Menu */}
      <Row className="mt-5">
        <Col>
          <h3 className="text-center mb-4">Our Catering Menu</h3>
          {/* You can use Card components to display different categories */}
          <Card>
            <Card.Header>Appetizers</Card.Header>
            <Card.Body>
              <Card.Text>
                Explore our selection of mouthwatering appetizers that will leave your guests wanting more.
              </Card.Text>
              <Button variant="primary">View Appetizers</Button>
            </Card.Body>
          </Card>

          {/* Repeat similar Card components for other categories like Main Course, Desserts, etc. */}
        </Col>
      </Row>

      {/* Contact Information */}
      <Row className="mt-5">
        <Col>
          <h3 className="text-center mb-4">Contact Us for Catering</h3>
          <Card>
            <Card.Body>
              <Card.Title>Let's Make Your Event Memorable</Card.Title>
              <Card.Text>
                Contact our catering team to discuss your event requirements and customize a menu that suits your preferences.
              </Card.Text>
              <Card.Text>
               Contact Us at +1234567890
              </Card.Text>
              {/* <Button variant="primary">Contact Us</Button> */}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Catering;

