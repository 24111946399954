import React from 'react';
import { Container, Row, Col, Carousel, Button } from 'react-bootstrap';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Home.css'; // Import the CSS file

function MeatGrocery() {
  const carouselImages = [
    '/images/meat1.jpg',
    '/images/meat2.jpg',
    '/images/meat3.jpg',
  ];

  const contactInfo = {
    phoneNumber: '+1 (123) 456-7890',
    email: 'info@meatandgrocery.com',
  };

  return (
    <div>
      {/* Carousel Section */}
      <Carousel fade interval={1000}>
        {carouselImages.map((image, index) => (
          <Carousel.Item key={index}>
            <img className="d-block w-100 custom-carousel-image" src={image} alt={`Slide ${index + 1}`} />
          </Carousel.Item>
        ))}
      </Carousel>

      {/* Meat Details Section */}
      <Container className="mt-4">
        <h2 className="text-center mb-4">Explore Our Zabiha Halal Meat</h2>
        <Row>
          <Col md={6}>
            <p>
              Our meat is Zabiha Halal, ensuring the highest quality and adherence to Islamic dietary laws.
              We take pride in providing fresh and delicious meat for your culinary needs.
            </p>
            <p>
              For large orders, we offer home delivery services. Experience the convenience of getting
              high-quality halal meat delivered to your doorstep.
            </p>
          </Col>
          <Col md={6}>
            <p>
              To place large orders or inquire about our products, feel free to contact us at:
              <br />
              Phone: +1 (972) 596-7799
              <br />
              Email: foodparadisetexas@gmail.com
            </p>
          </Col>
        </Row>
      </Container>

      {/* Contact Section */}
      {/* <Container className="mt-4">
        <h2 className="text-center mb-4">Contact Us</h2>
        <Row className="justify-content-center">
          <Col md={6}>
            <p>
              If you have any questions or need assistance, don't hesitate to reach out to us.
              We are here to provide you with the best service possible.
            </p>
            <Button variant="primary" href={`tel:${contactInfo.phoneNumber}`}>
              Call Us
            </Button>
            <Button variant="info" href={`mailto:${contactInfo.email}`} className="ml-2">
              Email Us
            </Button>
          </Col>
        </Row>
      </Container> */}
    </div>
  );
}

export default MeatGrocery;
