import React from 'react';
import { Container, Row, Col, Accordion, Card ,Button} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Appetizers from './Appetizers';
import Burgers from './Burgers';
import SouthIndianBreakfast from './SouthIndianBreakfast';
import SouthIndianEveningSnacks from './IndianEveningSnacks';


function OurRestaurant() {
  const videoStyle = {
    position: 'absolute',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    zIndex: '-1',
  };

  const jumbotronStyle = {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    color: '#fff', // Text color
  };
  const headingStyle = {
    fontSize: '4rem',
    fontWeight: 'bold',
    marginBottom: '20px',
    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
  };

  const leadStyle = {
    fontSize: '1.5rem',
    maxWidth: '600px',
    margin: '0 auto',
    textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)',
  };

  const menuContainerStyle = {
    backgroundColor: '#f8f9fa', // Update with your desired background color
    padding: '50px 0',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Add a subtle box shadow
    borderRadius: '15px', // Add rounded corners
  };

  const menuHeadingStyle = {
    fontSize: '2.5rem',
    fontWeight: 'bold',
    marginBottom: '40px',
    color: '#343a40', // Update with your desired text color
    textTransform: 'uppercase', // Convert text to uppercase
    letterSpacing: '1px', // Add letter-spacing
  };

  return (
    <div>
      <div className="jumbotron jumbotron-fluid" style={jumbotronStyle}>
        {/* Video Background */}
        <video src='/videos/homevideo.mp4' autoPlay loop muted style={videoStyle} />

        {/* Content */}
        <Container style={{ zIndex: '1', position: 'relative' }}>
          <h1 style={headingStyle}>Welcome to Our Restaurant</h1>
          <p style={leadStyle}>
            Discover a culinary journey filled with delightful flavors and unique dishes.
          </p>
        </Container>
        </div>
       {/* <div>
        <h4>Explore our Menu</h4>
        <Link to="https://www.ubereats.com/store/food-paradise/ipBQes3IUTW7tYFih6D4pQ?diningMode=DELIVERY">
              <Button variant="primary">View Menu</Button>
            </Link>
       </div> */}
       <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '30vh' }}>
      <div style={{ textAlign: 'center' }}>
        <h4>Explore our Menu</h4>
        <Link to="https://www.ubereats.com/store/food-paradise/ipBQes3IUTW7tYFih6D4pQ?diningMode=DELIVERY">
          <Button variant="primary">View Menu</Button>
        </Link>
      </div>
    </div>
    </div>
  );
}

export default OurRestaurant;
