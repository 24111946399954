import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
<footer className="bg-dark text-light py-4">
      <Container>
        <Row className="text-center">
          <Col md={4} xs={12} className="mb-3">
            <h5>About Us</h5>
            {/* <p>Discover the culinary journey at Food Paradise, where flavors come alive and memories are made.</p> */}
            <Link to='/' className="text-decoration-none text-light" style={{ display: 'block' }} >Home</Link>
            <Link to='/meatgrocery' className="text-decoration-none text-light" style={{ display: 'block'}} >Meat Store</Link>
            <Link to='/ourrestaurant' className="text-decoration-none text-light" style={{ display: 'block'}}>Our Restaurant</Link>
            {/* <Link to='/catering' className="text-decoration-none text-light" style={{ display: 'block' }}>Catering</Link> */}
          </Col>

          <Col md={4} xs={12} className="mb-3">
            <h5>Contact Us</h5>
            <p>Email: foodparadisetexas@gmail.com</p>
            <p>Restaurant Phone: +1 (972) 596-7786</p>
            <p>Meat Store Phone: +1 (972) 596-7799</p>
          </Col>

          <Col md={4} xs={12} className="mb-3">
            <h5>Follow Us On</h5>
            <div className="d-flex justify-content-center">
              <a href="https://www.facebook.com/share/WySqtWYSB5Fa37Jy/?mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer" className="text-light me-3">
                <FaFacebook size={30} />
              </a>
              {/* <a href="https://twitter.com/" target="_blank" rel="noopener noreferrer" className="text-light me-3">
                <FaTwitter size={30} />
              </a> */}
              <a href="https://www.instagram.com/foodparadiseplano?igsh=dTBmam5mbWg5b2Nr" target="_blank" rel="noopener noreferrer" className="text-light me-3">
                <FaInstagram size={30} />
              </a>
              {/* <a href="https://www.linkedin.com/" target="_blank" rel="noopener noreferrer" className="text-light">
                <FaLinkedin size={30} />
              </a> */}
            </div>
          </Col>
        </Row>

        <hr className="bg-light" />

        <Row>
          <Col className="text-center">
            <p className="mb-0">&copy; 2024 Food Paradise</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
