import "./App.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import OurRestaurant from "./components/OurRestaurant";
import Home from "./components/Home";
import ContactUs from "./components/ContactUs";
//import Menu from "./components/Menu";
import Catering from "./components/Catering";
import OrderOnline from "./components/OrderOnline";
import MeatGrocery from "./components/MeatGrocery";
//import RateProperty from "./components/RateProperty";
//import ShowRatings from "./components/ShowRatings";
//import Reservation from "./components/Reservation";
//import MakeReservation from "./components/MakeReservation";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";


function App() {
  return (
    <Router>
    <div className="app">
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/ourrestaurant" element={<OurRestaurant />} />
        <Route path="/meatgrocery" element={<MeatGrocery />} />
        <Route path="/catering" element={<Catering />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/orderonline" element={<OrderOnline />} />
      </Routes>
      <Footer />
    </div>
  </Router>
  );
}

export default App;
