import logo from "./logo.png";
import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar'; 
import { Link } from 'react-router-dom';
             

function Header() {
  return (

    <Navbar bg="dark" expand="lg" variant="dark">
    <Navbar.Brand href="#">
    <Link to="/" style={{fontFamily: 'Rosella, Inline', fontSize: '45px', fontWeight: 'bold', color: 'gold',textDecoration: 'none'}}><img id="logo" style={{marginLeft:"3rem", width:'130px', height:'80px'}} src={logo} alt="Logo" />FOOD PARADISE</Link>
   
    </Navbar.Brand>
    <Navbar.Toggle aria-controls="navbarScroll" />
    <Navbar.Collapse id="navbarScroll" className="justify-content-end" style={{marginRight:"5rem"}}>
      <Nav
        className="mr-auto my-2 my-lg-0"
        style={{ maxHeight: '100px' }}
        navbarScroll
      >
                <Nav.Link href="/" style={{marginRight:"2rem"}}>Home</Nav.Link>
                <Nav.Link href="/meatgrocery" style={{marginRight:"2rem"}}>Meat Store</Nav.Link>
                <Nav.Link href="/ourrestaurant" style={{marginRight:"2rem"}}>Our Restaurant</Nav.Link>
                <Nav.Link href="https://www.ubereats.com/store/food-paradise/ipBQes3IUTW7tYFih6D4pQ?diningMode=DELIVERY" style={{marginRight:"2rem"}}>Order Online</Nav.Link>
                {/* <Nav.Link href="/catering" style={{marginRight:"2rem"}}>Catering</Nav.Link> */}
                <Nav.Link href="/contactus" style={{marginRight:"2rem"}}>Contact Us</Nav.Link>
      </Nav>
    </Navbar.Collapse>
  </Navbar>
  );
}

export default Header;