import React from 'react';
import { Carousel, Container, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Home = () => {
  return (
    <div>
      {/* Carousel Section */}
      <Carousel pause={false}>
        {/* Slide 1 */}
        <Carousel.Item>
          <img
            className="d-block w-100 medium-image"
            src="/images/home1.jpeg"
            alt="Meat Store"
          />
          <Carousel.Caption>
            <h3>Meat Offerings</h3>
            <p>Explore our high-quality meat products.</p>
            <Link to="/meatgrocery">
              <Button variant="primary">Learn More</Button>
            </Link>
          </Carousel.Caption>
        </Carousel.Item>

        {/* Slide 2 */}
        <Carousel.Item>
          <img
            className="d-block w-100 medium-image"
            src="/images/home2.jpeg"
            alt="Our Restaurant"
          />
          <Carousel.Caption>
            <h3>Our Restaurant</h3>
            <p>Experience a delightful dining journey at Food Paradise.</p>
            <Link to="/ourrestaurant">
              <Button variant="primary">Explore</Button>
            </Link>
          </Carousel.Caption>
        </Carousel.Item>

        {/* Slide 3 */}
        <Carousel.Item>
          <img
            className="d-block w-100 medium-image"
            src="/images/home3.jpeg"
            alt="Catering Services"
          />
          <Carousel.Caption>
            <h3>Catering Services</h3>
            <p>Contact us to cater your special events with our exquisite offerings.</p>
            {/* <Link to="/about">
              <Button variant="primary">Contact</Button>
            </Link> */}
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>

      {/* Additional Content Section */}
      <Container className="welcome-container">
        <div className="welcome-content">
          <h2 className="my-4">Welcome to Food Paradise</h2>
          <p>
            At Food Paradise, we are passionate about delivering exceptional culinary experiences. Explore our website to discover a variety of offerings, from high-quality meat and grocery products to our delightful restaurant and catering services.
          </p>
          {/* Add more content and sections as needed */}
        </div>
      </Container>
      {/* Add the inline styles here */}
      <style>
        {`
          .medium-image {
            max-height: 600px; /* Adjust the height as needed */
            max-width: 100%; /* Set the maximum width to 100% of the container */
            margin: auto; /* Center the image */
          }
          .welcome-container {
            background-color: #f8f9fa; /* Add a background color */
            padding: 50px 0; /* Add some padding for spacing */
            text-align: center; /* Center-align text */
          }
          
          .welcome-content {
            max-width: 800px; /* Limit the content width */
            margin: 0 auto; /* Center the content within the container */
          }
        `}
      </style>
    </div>
  );
};

export default Home;
